@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,700;1,100;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-search-form .position {
  width: calc(100% / 1370 * 520);
}

.main-search-form .date {
  width: calc(100% / 1370 * 313);
}

.main-search-form .users {
  width: calc(100% / 1370 * 330);
}

.main-search-form .btn {
  width: calc(100% / 1370 * 205);
}

.main-search-from input {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 22px;
  color: #494949;
}

.react-calendar {
  padding: 10px;
}

.react-calendar__navigation {
  font-weight: bold;
}

.react-calendar__tile:disabled {
  background-color: white !important;
  color: #A9A9A9 !important;
}

.react-calendar__tile--now {
  background-color: white !important;
}

.react-calendar__tile--active {
  background-color: rgba(243, 227, 81, 0.2) !important;
  color: black !important;
}

.react-calendar__tile--rangeStart {
  background-color: #F3E351 !important;
  color: black !important;
  border-radius: 50% !important;
}

.react-calendar__tile--rangeEnd {
  background-color: #F3E351 !important;
  color: black !important;
  border-radius: 50% !important;
}

/* .react-calendar__tile--now {
  background-color: rgb(82, 82, 235) !important;
  color: white !important;
  border-radius: 50% !important;
} */
